import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { FirebaseContext } from "../components/Firebase"

const Login = () => {
  const [formValues, setFormValues] = useState({ email: "", password: "" })
  const { firebase } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState("")
  let isMounted = true

  useEffect(() => {
    return () => {
      isMounted = false
    }
  }, [])

  function handleSubmit(e) {
    e.preventDefault()

    firebase
      .login({ email: formValues.email, password: formValues.password })
      .catch(error => {
        console.log(error)
        setErrorMessage(error.message)
      })
  }

  function handleInputChange(e) {
    e.persist()
    setErrorMessage("")
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
  }
  return (
    <section>
      <form onSubmit={handleSubmit}>
        <input
          value={formValues.email}
          name="email"
          onChange={handleInputChange}
          placeholder="email"
          type="email"
        />
        <input
          value={formValues.password}
          name="password"
          onChange={handleInputChange}
          placeholder="password"
          type="password"
        />
        {errorMessage && <span>{errorMessage}</span>}
        <button type="submit">Login</button>
      </form>
    </section>
  )
}

export default Login
